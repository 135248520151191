import React, { useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import { getImageFromS3 } from '../../../imageService';
import classNames from 'classnames';
import global from '../../index.module.scss';

const TrueFalseSohoChallenge = ({
  setScreenNumber,
  backgroundImage,
  challengeList,
  resultList,
  setAfterResultScreen
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [challengeResult, setChallengeResult] = useState([]);
  const [isShowCorrectResult, setIsShowCorrectResult] = useState(false);
  const [isResultView, setIsResultView] = useState(false);
  const [isAfterResult, setIsAfterResult] = useState(false);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [result, setResult] = useState(null);

  const handleSelect = (answer, checkedValue) => {
    const isExistAnswer = challengeResult.find(
      (item) => item.questionId === selectQuestion.id
    );
    if (isExistAnswer) {
      const updatedResultList = challengeResult.filter(
        (item) => item.questionId !== selectQuestion.id
      );
      setChallengeResult([
        ...updatedResultList,
        {
          questionId: selectQuestion.id,
          answerId: answer.id,
          isCorrect: answer?.isCorrect === checkedValue
        }
      ]);
    } else {
      setChallengeResult([
        ...challengeResult,
        {
          questionId: selectQuestion.id,
          answerId: answer.id,
          isCorrect: answer?.isCorrect === checkedValue
        }
      ]);
    }
    setIsShowCorrectResult(true);
  };

  const handleViewResult = () => {
    let correctAnswersCount = challengeResult?.filter(
      (item) => item?.isCorrect
    )?.length;
    const correctAnswerIndex =
      correctAnswersCount <= 2
        ? 1
        : correctAnswersCount >= 3 && correctAnswersCount <= 5
        ? 0
        : 2;
    setResult(resultList[correctAnswerIndex]);
    setIsResultView(true);
  };

  return (
    <div
      className={styles.trueFalseSohoChallenge}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '0 30px' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultView && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                'images/percentage-of-your-bohemian-challenge/circle.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>Правда/Неправда про Сохо</p>
          </div>
          <p className={styles.description}>
            Твоя особистість — це стовідсотковий житель Сохо. А чи зміг би ти
            провести екскурсію по цьому району?<br></br>
            Знаходь правдиві факти про Сохо.<br></br>
            Пройти тест!
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Почати</button>
        </div>
      )}
      {!isResultView && !result && !isWelcomeScreen && !isAfterResult && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          {selectQuestion && (
            <div className={styles.questionItem}>
              {!isShowCorrectResult ? (
                <span className={styles.titleQuestion}>
                  {selectQuestion.title}
                </span>
              ) : (
                <div
                  className={classNames(
                    styles.answerTitleContainer,
                    selectQuestion?.isCorrect && styles.trueTitleBackground
                  )}
                >
                  {selectQuestion?.isCorrect ? 'ПРАВДА!' : 'БРЕХНЯ!'}
                </div>
              )}
              <div className={styles.imageList}>
                <div id={selectQuestion.id} className={styles.imageContainer}>
                  <img
                    src={selectQuestion.image}
                    alt={selectQuestion.id}
                    className={styles.imageItem}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={styles.buttonContainer}>
            {!isShowCorrectResult ? (
              <>
                <button
                  className={global.menuButton}
                  onClick={() => handleSelect(selectQuestion, true)}
                >
                  Правда
                </button>
                <button
                  className={global.errorButton}
                  onClick={() => handleSelect(selectQuestion, false)}
                >
                  Брехня
                </button>
              </>
            ) : (
              <button
                className={global.menuButton}
                onClick={() => {
                  if (challengeList.length > challengeStep + 1) {
                    setSelectQuestion(challengeList[challengeStep + 1]);
                    setChallengeStep(challengeStep + 1);
                    setIsShowCorrectResult(false);
                  } else {
                    handleViewResult();
                    setIsShowCorrectResult(false);
                  }
                }}
              >
                Далі
              </button>
            )}
          </div>
        </>
      )}
      {isResultView && result && (
        <div className={styles.resultContainer}>
          <div>
            <h3 className={styles.resultHeading}>Результат</h3>
          </div>
          <img src={result?.image} alt="result" />
          <div className={styles.description}>
            <p className={styles.resultDescription}>{result.text}</p>
          </div>
          <button
            className={global.reviewButton}
            onClick={() => {
              setIsAfterResult(true);
              setIsResultView(false);
              setIsWelcomeScreen(false);
            }}
          >
            Далі
          </button>
        </div>
      )}
      {isAfterResult && !isResultView && !isWelcomeScreen && (
        <div className={styles.afterResultContainer}>
          <img
            src={getImageFromS3(
              'images/true-false-challenge/soho/after-result.png'
            )}
            alt="after-result"
          />
          <p className={styles.title}>Багаж знань поповнено!</p>
          <div className={styles.description}>
            Як у нас кажуть: вік живи – вік дізнавайся нове про Сохо.
          </div>
          <button className={global.menuButton} onClick={setAfterResultScreen}>
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default TrueFalseSohoChallenge;
