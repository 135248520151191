import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import classNames from 'classnames';
import TinderCard from 'react-tinder-card';
import { getImageFromS3 } from '../../../imageService';
import global from '../../index.module.scss';

let leftCount = 0;
let rightCount = 0;

const WhatKindSohoYouChallenge = ({
  setScreenNumber,
  backgroundImage,
  challengeList,
  challengeResultList,
  setScreenNumberAfterResult
}) => {
  const [result, setResult] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(challengeList.length - 1);
  const currentIndexRef = useRef(currentIndex);
  const [isResultView, setIsResultView] = useState(false);
  const [isAfterResult, setIsAfterResult] = useState(false);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);

  const childRefs = useMemo(
    () =>
      Array(challengeList.length)
        .fill(0)
        .map((i) => React.createRef()),
    [challengeList]
  );

  const updateCurrentIndex = useCallback(
    (val) => {
      setCurrentIndex(val);
      currentIndexRef.current = val;
    },
    [currentIndexRef]
  );

  const canSwipe = useMemo(() => currentIndex >= 0, [currentIndex]);

  const resultView = useCallback(() => {
    if (leftCount > rightCount) {
      setResult(challengeResultList[0]);
      setIsResultView(true);
      setIsWelcomeScreen(false);
    } else {
      setResult(challengeResultList[1]);
      setIsResultView(true);
      setIsWelcomeScreen(false);
    }
  }, [leftCount, rightCount]);

  const swiped = useCallback(
    (direction, index) => {
      if (index - 1 >= 0) {
        updateCurrentIndex(index - 1);

        if (direction === 'left') leftCount = leftCount + 1;
        if (direction === 'right') rightCount = rightCount + 1;
      } else {
        resultView();
      }
    },
    [leftCount, rightCount, resultView]
  );

  const outOfFrame = useCallback(
    (idx) => {
      if (childRefs[idx].current) {
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
      }
    },
    [childRefs, currentIndexRef]
  );

  const swipe = useCallback(
    async (direction) => {
      if (canSwipe && currentIndex >= 0) {
        await childRefs[currentIndex].current.swipe(direction);
      } else {
        resultView();
      }
    },
    [currentIndex, resultView, result]
  );

  return (
    <div
      className={styles.swipeChallengeContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcomeScreen && !isResultView && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                'images/percentage-of-your-bohemian-challenge/circle.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>Який ти Сохо?</p>
          </div>
          <p className={styles.description}>
            Сохо ніколи не спить. А ще у кожну пору доби він різний, що говорить
            про контраст його мешканців.<br></br>
            <br></br>
            <b>
              Відповідай “так” або “ні” та дізнайся, який ти Сохо та про що це
              свідчить.
            </b>
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>Дізнатись</button>
        </div>
      )}
      {!isWelcomeScreen && !isResultView && !isAfterResult && (
        <div className={classNames(!!result && styles.noDisplay)}>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === challengeList.length - 1 - currentIndex &&
                    styles.activeStepItem
                )}
                key={index.toString()}
              />
            ))}
          </div>
          <p className={styles.title} id={challengeList[currentIndex]?.title}>
            {challengeList[currentIndex]?.title}
          </p>
          <div className={styles.cardContainer}>
            {challengeList.map((item, index) => {
              return (
                <TinderCard
                  ref={childRefs[index]}
                  className={styles.swipe}
                  key={index.toString()}
                  onSwipe={(dir) => swiped(dir, index)}
                  preventSwipe={['top', 'bottom']}
                  onCardLeftScreen={() => outOfFrame(index)}
                >
                  <div
                    style={{ backgroundImage: 'url(' + item.image + ')' }}
                    className={styles.card}
                  />
                </TinderCard>
              );
            })}
          </div>
          <div className={styles.buttonContainer}>
            <img
              src={getImageFromS3('images/common/swipe-cancel-button.png')}
              alt="cancel-button"
              onClick={() => swipe('left')}
            />
            <img
              src={getImageFromS3('images/common/swipe-left-arrow.png')}
              alt="left-arrow"
            />
            <img
              src={getImageFromS3('images/common/swipe-right-arrow.png')}
              alt="right-arrow"
            />
            <img
              src={getImageFromS3('images/common/swipe-approve-button.png')}
              className="w-[73px]"
              alt="approve-button"
              onClick={() => swipe('right')}
            />
          </div>
        </div>
      )}
      {result && isResultView && (
        <div className={styles.resultContainer}>
          <p className={styles.headTitle}>Результат</p>
          <img src={result?.image} alt="result-image" />
          <div className={styles.resultDescription}>
            <p className={styles.resultTitle}>{result?.title}</p>
            <p className={styles.resultSubtitle}>{result?.description}</p>
          </div>
          <button
            className={global.reviewButton}
            onClick={() => {
              setIsAfterResult(true);
              setIsResultView(false);
            }}
          >
            Далі
          </button>
        </div>
      )}
      {!isResultView && isAfterResult && (
        <div className={styles.afterResultContainer}>
          <img
            src={getImageFromS3(
              'images/what-kind-soho-you-challenge/after-result.png'
            )}
            alt="after-result-image"
          />
          <p className={styles.afterResultTitle}>Погоджуєшся з результатом?</p>
          <p className={styles.afterResultDescription}>
            Вранці чи ввечері – Сохо завжди готовий дарувати незабутні враження
            кожному.
          </p>
          <button
            className={global.menuButton}
            onClick={setScreenNumberAfterResult}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default WhatKindSohoYouChallenge;
