import React, { useState } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import AuthorityScreen from '../common/authority-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import { useContext } from 'react';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import { useEffect } from 'react';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import AdviceChallenge from '../common/challenge-component/advice-challenge';
import { fiveSohoFactsChallengeList } from '../consts/soho-facts-challenge';
import InstantQuizChallenge from '../common/challenge-component/instant-quiz-challenge';
import FinalScreen from '../components/lm-d-s/final-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';
import SohoTourChallenge from '../common/challenge-component/soho-tour-challenge';

const menu = [
  {
    id: 'challenge',
    screenNumber: 13,
    name: 'Челендж квітня',
    icon: 'images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 15,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: 'images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: 'images/lm-double-splash/review-menu-icon.svg'
  }
];

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/parliament/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=parliament/'
};

const selectedPack = {
  title: 'Parliament Soho Vibe',
  pack: getImageFromS3('images/packs/parliament/soho-vibe.png'),
  tobaccoFirst: 'Вміст смол, мг - 4',
  tobaccoSecond: 'Вміст нікотину, мг - 0.3',
  filterIcon: getImageFromS3('images/parliament/filter.png'),
  background: `url("${getImageFromS3('images/parliament/bg.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Super Slim (Кінг сайз супер слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: <span>Ацетатна частина</span>,
      bordered: true
    },
    {
      description: <span>Вугільна частина</span>,
      bordered: true
    },
    {
      description: (
        <span>
          Ущільнена повітряна камера з ацетатною стінкою, що робить смак м'яким
          і більш збалансованим
        </span>
      ),
      bordered: true
    }
  ]
};

const PRL_V = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('Parliament soho vibe');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(14);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/parliament/bg.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            partners={partners}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="parliament"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/soho-facts.mp4')}
            setScreenNumber={cb}
            nextScreenNumber={6}
            changeScreenImmediately
            skipScreenImmediately
            controls={false}
            buttonStyle="skip-video"
            hasButtonArrow={true}
          />
        );
      }
      case 6: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={setScreenNumber}
            backgroundImage={`url("${getImageFromS3(
              'images/parliament/bg.png'
            )}")`}
            title={
              <>
                5 фактів<br></br>про Сохо
              </>
            }
            description="Від унікальної архітектури до творчих оаз — район Сохо в Нью-Йорку манить туристів своєю атмосферою. Ми підготували 5 фактів про Сохо, щоб познайомити тебе ближче з яскравим районом."
            buttonTitle="Почати"
            setNextScreenNumber={() => setScreenNumber(7)}
          />
        );
      }
      case 7: {
        return (
          <AdviceChallenge
            setScreenNumber={cb}
            challengeList={fiveSohoFactsChallengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/parliament/bg.png'
            )}")`}
            setScreenNumberAfterResult={() => setScreenNumber(9)}
            withCounter={false}
          />
        );
      }
      case 9: {
        return (
          <InstantQuizChallenge
            setScreenNumber={setScreenNumber}
            type="soho-facts"
            backgroundImage={`url("${getImageFromS3(
              'images/parliament/bg.png'
            )}")`}
            setScreenNumberAfterResult={() => setScreenNumber(10)}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return (
          <SohoTourChallenge
            setScreenNumber={setScreenNumber}
            afterResultButtonScreen={() => setScreenNumber(10)}
          />
        );
      }
      case 14: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      case 15: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="lm-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default PRL_V;
