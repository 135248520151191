import React, { useState, useEffect, useContext } from 'react';
import { ScreensContext } from '../contexts/screensContext';
import MenuScreen from '../common/menu-screen';
import AuthorityScreen from '../common/authority-screen';
import styles from '../components/lm-d-s/index.module.scss';
import InfoScreen from '../components/lm-d-s/info-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import ChallengeScreen from '../components/lm-d-s/challenge-screen';
import QuizScreen from '../components/lm-d-s/quiz-screen';
import FinalQuizScreen from '../components/lm-d-s/final-quiz-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import LmLineScreen from '../components/lm-d-s/lm-line-screen';
import QuizChallenge from '../common/challenge-component/quiz-challenge';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';
import TripleChoiceChallenge from '../common/challenge-component/triple-choice-challenge';

const menu = [
  {
    id: 'challenge',
    screenNumber: 15,
    name: 'Челендж квітня',
    icon: '/images/lm-double-splash/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 9,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: '/images/lm-double-splash/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: '/images/lm-double-splash/info-menu-icon.svg'
  },
  {
    id: 'authority',
    screenNumber: 2,
    name: 'Як відрізнити підробку від оригіналу?',
    icon: '/images/lm-double-splash/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 12,
    name: 'Залишити відгук про продукт',
    icon: '/images/lm-double-splash/review-menu-icon.svg'
  }
];

const partners = {
  cig: 'https://cig-poshta.in.ua/shop/marlboro/?utm_source=SP',
  rozetka: 'https://rozetka.com.ua/ua/sigareti/c4638591/producer=marlboro/'
};

const selectedPack = {
  title: 'Marlboro Fine Touch',
  pack: getImageFromS3('images/packs/marlboro/fine-touch.png'),
  tobaccoFirst: 'Вміст смол, мг - 4',
  tobaccoSecond: 'Вміст нікотину, мг - 0.3',
  filterIcon: getImageFromS3('images/marlboro/filter.png'),
  background: `url("${getImageFromS3('images/marlboro/red-background.png')}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size Slim (Кінг сайз слім)'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          Ущільнений ацетатний фільтр, який краще тримає форму та менше розмокає
        </span>
      ),
      bordered: false
    }
  ]
};

const MLBFT = () => {
  const [screenNumber, setScreenNumber] = useState(1);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => {
    init('Marlboro fine touch');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(16);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menu}
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <AuthorityScreen
            setScreenNumber={cb}
            partners={partners}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="lm"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            newMixtureText={selectedPack?.newMixtureText}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(10)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            characteristicListTitle={selectedPack?.characteristicListTitle}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return <ChallengeScreen setScreenNumber={cb} />;
      }
      case 6: {
        return <QuizScreen setScreenNumber={cb} />;
      }
      case 7: {
        return <QuizScreen setScreenNumber={cb} />;
      }
      case 8: {
        return <FinalQuizScreen setScreenNumber={cb} />;
      }
      case 9: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      case 10: {
        return <FinalScreen setScreenNumber={cb} withReviewButton />;
      }
      case 11: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 12: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(11)}
          />
        );
      }
      case 13: {
        return <LmLineScreen setScreenNumber={cb} />;
      }
      case 14: {
        return (
          <QuizChallenge
            setScreenNumber={setScreenNumber}
            type="which-are-you-a-leader"
            backgroundImage={`url("${getImageFromS3(
              'images/marlboro/red-background.png'
            )}")`}
            resultTitleFontSize={24}
          />
        );
      }
      case 15: {
        return (
          <TripleChoiceChallenge
            setScreenNumber={setScreenNumber}
            challengeId="what-is-your-vibe"
            afterResultButtonScreen={() => setScreenNumber(10)}
          />
          // <DifferencesChallenge
          //   setScreenNumber={setScreenNumber}
          //   type="find-differenсes"
          //   backgroundImage={`url("${getImageFromS3(
          //     'images/marlboro/background.png'
          //   )}")`}
          // />
        );
      }
      case 16: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(10)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer} id="mlb-f-t-screen-container">
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default MLBFT;
