import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import HeadContainer from '../../head-container';
import { getImageFromS3 } from '../../../imageService';
import classNames from 'classnames';
import global from '../../index.module.scss';
import TinderCard from 'react-tinder-card';

const challengeList = [
  {
    isReal: true,
    image: getImageFromS3('images/technology-or-reality-challenge/9.png')
  },
  {
    isReal: true,
    image: getImageFromS3('images/technology-or-reality-challenge/8.png')
  },
  {
    isReal: false,
    image: getImageFromS3('images/technology-or-reality-challenge/7.png')
  },
  {
    isReal: true,
    image: getImageFromS3('images/technology-or-reality-challenge/6.png')
  },
  {
    isReal: false,
    image: getImageFromS3('images/technology-or-reality-challenge/5.png')
  },
  {
    isReal: true,
    image: getImageFromS3('images/technology-or-reality-challenge/4.png')
  },
  {
    isReal: false,
    image: getImageFromS3('images/technology-or-reality-challenge/3.png')
  },
  {
    isReal: false,
    image: getImageFromS3('images/technology-or-reality-challenge/2.png')
  },
  {
    isReal: true,
    image: getImageFromS3('images/technology-or-reality-challenge/1.png')
  }
];

export const resultList = [
  {
    description:
      'Технології невпинно розвиваються і потрібно бути дуже уважним, щоб встигнути за ними.\n' +
      '\n' +
      'Тренуй пильність, щоб не потрапити у тенета диджиталізації.',
    image: getImageFromS3('/images/technology-or-reality-challenge/result1.png')
  },
  {
    description:
      'Вітаємо, тобі майже вдалось відгадати усі зображення.\n' +
      '\n' +
      'Ще трохи зусиль і будеш на “ти” зі штучним інтелектом.',
    image: getImageFromS3('/images/technology-or-reality-challenge/result2.png')
  },
  {
    description:
      'Вау! Ти впізнав усі зображення, створені ШІ.\n' +
      '\n' +
      'Ти не лише добре розбираєшся в технологіях, а й вмієш використовувати їх на свою користь.',
    image: getImageFromS3('/images/technology-or-reality-challenge/result3.png')
  }
];

let leftCount = 0;
let rightCount = 0;
let correctResultCount = 0;

const TrueFalseSohoChallenge = ({
  setScreenNumber,
  backgroundImage,
  setScreenNumberAfterResult
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [isResultView, setIsResultView] = useState(false);
  const [isAfterResult, setIsAfterResult] = useState(false);
  const [result, setResult] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(challengeList.length - 1);
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(challengeList.length)
        .fill(0)
        .map((i) => React.createRef()),
    [challengeList]
  );

  const updateCurrentIndex = useCallback(
    (val) => {
      setCurrentIndex(val);
      currentIndexRef.current = val;
    },
    [currentIndexRef]
  );

  const canSwipe = useMemo(() => currentIndex >= 0, [currentIndex]);

  const resultView = useCallback(() => {
    setResult(
      resultList[
        correctResultCount <= 2
          ? 0
          : correctResultCount >= 3 && correctResultCount <= 4
          ? 1
          : 2
      ]
    );
    setIsResultView(true);
  }, [leftCount, rightCount]);

  const swiped = useCallback(
    (direction, index) => {
      if (index - 1 >= 0) {
        const isCorrect =
          challengeList[index]?.isReal === (direction === 'left');
        if (isCorrect) correctResultCount++;
        updateCurrentIndex(index - 1);

        if (direction === 'left') leftCount = leftCount + 1;
        if (direction === 'right') rightCount = rightCount + 1;
      } else {
        resultView();
      }
    },
    [leftCount, rightCount, resultView]
  );

  const outOfFrame = useCallback(
    (idx) => {
      if (childRefs[idx].current) {
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
      }
    },
    [childRefs, currentIndexRef]
  );

  const swipe = useCallback(
    async (direction) => {
      if (canSwipe && currentIndex >= 0) {
        await childRefs[currentIndex].current.swipe(direction);
        setChallengeStep(challengeStep + 1);
      } else {
        resultView();
      }
    },
    [currentIndex, resultView, result, challengeStep]
  );

  return (
    <div
      className={styles.trueFalseSohoChallenge}
      style={{ backgroundImage: backgroundImage }}
    >
      <div style={{ padding: '0 30px' }}>
        <HeadContainer setScreenNumber={setScreenNumber} />
      </div>
      {isWelcomeScreen && !isResultView && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                'images/technology-or-reality-challenge/circle.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>Технології чи реальність?</p>
          </div>
          <p className={styles.description}>
            Сучасні технології розвиваються шаленим темпом. А чи встигаєш ти за
            цим прогресом?<br></br>
            Спробуй відгадати, яка з двох картинок створена штучним інтелектом.
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>
            Приймаю челендж!
          </button>
        </div>
      )}
      {!isResultView && !result && !isWelcomeScreen && !isAfterResult && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          <p className={styles.title}>Яке фото згенероване нейромережею?</p>
          <div className={styles.cardContainer}>
            {challengeList.map((item, index) => {
              return (
                <TinderCard
                  ref={childRefs[index]}
                  className={styles.swipe}
                  key={index.toString()}
                  onSwipe={(dir) => swiped(dir, index)}
                  preventSwipe={['top', 'bottom']}
                  onCardLeftScreen={() => outOfFrame(index)}
                >
                  <div
                    style={{ backgroundImage: 'url(' + item.image + ')' }}
                    className={styles.card}
                  />
                </TinderCard>
              );
            })}
          </div>
          <div className={styles.buttonContainer}>
            <button className={global.menuButton} onClick={() => swipe('left')}>
              Справжнє
            </button>
            <button
              className={global.errorButton}
              onClick={() => swipe('right')}
            >
              Згенероване
            </button>
          </div>
        </>
      )}
      {isResultView && result && (
        <div className={styles.resultContainer}>
          <div>
            <h3 className={styles.resultHeading}>Результат</h3>
          </div>
          <img src={result?.image} alt="result" />
          <div className={styles.description}>
            <p className={styles.resultDescription}>{result.description}</p>
          </div>
          <button
            className={global.reviewButton}
            onClick={() => {
              setIsAfterResult(true);
              setIsResultView(false);
              setIsWelcomeScreen(false);
            }}
          >
            Далі
          </button>
        </div>
      )}
      {isAfterResult && !isResultView && !isWelcomeScreen && (
        <div className={styles.afterResultContainer}>
          <img
            src={getImageFromS3(
              'images/technology-or-reality-challenge/after-result.png'
            )}
            alt="after-result"
          />
          <div className={styles.description}>
            Будь завжди на крок попереду!<br></br>
            <br></br>
            Світ стрімко змінюється, тож важливо тримати руку на пульсі.
          </div>
          <button
            className={global.menuButton}
            onClick={setScreenNumberAfterResult}
          >
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default TrueFalseSohoChallenge;
