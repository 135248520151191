import React from 'react';
import { getImageFromS3 } from '../imageService';

export const fiveSohoFactsChallengeList = [
  {
    title: 'Мистецька мекка',
    description: (
      <p>
        Коли в 70-х роках індустріальні будівлі району перетворили на житлові,
        Сохо став улюбленим місцем митців із різних куточків світу. Тепер тут є
        багато галерей, стінописів та художніх інсталяцій.
      </p>
    ),
    image: getImageFromS3('images/parliament/challenge/soho-facts/1.png')
  },
  {
    title: 'Чавунні фасади',
    description: (
      <p>
        Цікава архітектурна особливість Сохо — фасади будівель із чавуну. Вони
        прикрашають вулиці своїми вишуканими орнаментами й історичною
        елегантністю.
      </p>
    ),
    image: getImageFromS3('images/parliament/challenge/soho-facts/2.png')
  },
  {
    title: 'Маленька Італія',
    description: (
      <p>
        Колись у частині Сохо розташовувалася Маленька Італія — район із
        жителями італійського походження. Цю частину історії сьогодні можна
        побачити в меню ресторанів та в культурних заходах Сохо.
      </p>
    ),
    image: getImageFromS3('images/parliament/challenge/soho-facts/3.png')
  },
  {
    title: 'Зірка кіно',
    description: (
      <p>
        Завдяки своїй атмосфері Сохо став знімальним майданчиком для багатьох
        фільмів. Тут можна впізнати декорації, знайомі нам із великих екранів.
      </p>
    ),
    image: getImageFromS3('images/parliament/challenge/soho-facts/4.png')
  },
  {
    title: 'Мода та стиль',
    description: (
      <p>
        У Сохо безмежна кількість бутиків від світових брендів та місцевих
        дизайнерських магазинчиків. Саме тому це справжній магніт для модних
        ентузіастів.
      </p>
    ),
    image: getImageFromS3('images/parliament/challenge/soho-facts/5.png')
  }
];

export const whatKindSohoYouChallengeList = [
  {
    title: 'Твої рішення спонтанні, ти не любиш планувати заздалегідь',
    image: getImageFromS3('images/what-kind-soho-you-challenge/1.png')
  },
  {
    title: 'Ти любиш зустрічі з друзями в атмосферних місцях, а не вдома',
    image: getImageFromS3('images/what-kind-soho-you-challenge/2.png')
  },
  {
    title: 'Тобі більше подобається хаос і рух, ніж тиша та порядок',
    image: getImageFromS3('images/what-kind-soho-you-challenge/3.png')
  },
  {
    title: 'Любиш гуляти містом у час, коли воно ще/вже пусте',
    image: getImageFromS3('images/what-kind-soho-you-challenge/4.png')
  },
  {
    title:
      'Зранку довго налаштовуєшся на роботу, зате вночі продуктивний як ніколи',
    image: getImageFromS3('images/what-kind-soho-you-challenge/5.png')
  }
];

export const whatKindSohoYouResultList = [
  {
    title: 'Ти — нічний Сохо',
    description:
      'Твій час починається, коли інші засинають.\n' +
      'Ти драйвовий і харизматичний, для тебе життя — це нескінченний потік яскравих подій та можливостей.',
    image: getImageFromS3('images/what-kind-soho-you-challenge/result1.png')
  },
  {
    title: 'Ти — ранковий Сохо',
    description:
      'Ти належиш до тих, хто працює в гармонії з першими променями сонця. Світанки, свіже повітря, спокій і натхнення — твоя суперсила.',
    image: getImageFromS3('images/what-kind-soho-you-challenge/result2.png')
  }
];

export const percentageOfYourBohemianChallenge = [
  {
    id: '1',
    title: 'Який сценарій вечора є найпривабливішим для тебе?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/1.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/1.2.png'
        )
      }
    ]
  },
  {
    id: '2',
    title: 'Який формат подорожей твій улюблений?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/2.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/2.2.png'
        )
      }
    ]
  },
  {
    id: '3',
    title: 'Як ти зазвичай вибираєш ресторан для вечері?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/3.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/3.2.png'
        )
      }
    ]
  },
  {
    id: '4',
    title: 'Який сценарій принесе тобі найбільше задоволення?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/4.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/4.2.png'
        )
      }
    ]
  },
  {
    id: '5',
    title: 'Як ти святкуєш свій день народження?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/5.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/5.2.png'
        )
      }
    ]
  },
  {
    id: '6',
    title: 'Як ти ухвалюєш рішення?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/6.1.png'
        )
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/percentage-of-your-bohemian-challenge/6.2.png'
        )
      }
    ]
  }
];

export const percentageOfYourBohemianResultList = [
  {
    resultId: '1',
    titleDescription: 'Ти обожнюєш стабільність та зручність.',
    textDescription:
      'Де б ти не був, намагаєшся створити затишок та надихаючу рутину. З тобою завжди комфортно та надійно.',
    image: getImageFromS3(
      'images/percentage-of-your-bohemian-challenge/result1.png'
    )
  },
  {
    resultId: '2',
    titleDescription: 'Ти стовідсотковий новатор!',
    textDescription:
      'Вже протоптані стежки не приваблюють тебе, адже ти створюєш власні унікальні дороги.',
    image: getImageFromS3(
      'images/percentage-of-your-bohemian-challenge/result2.png'
    )
  },
  {
    resultId: '3',
    titleDescription:
      'Ти вмієш прислухатись до себе та завжди знаходиш для себе найкращий вихід.',
    textDescription:
      'Мінливість світу тебе не лякає, адже ти можеш з легкістю пристосуватись до будь-яких умов.',
    image: getImageFromS3(
      'images/percentage-of-your-bohemian-challenge/result3.png'
    )
  }
];

export const representativeOfSubcultureChallengeList = [
  {
    id: '1',
    title:
      'Емілі часто відвідує бари та ресторани. Досліджує гастрономію та ділиться цим в соцмережах.\n' +
      'На якому фото Емілі та до якої культури вона належить?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/1.1.png'
        ),
        isCorrect: true
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/1.2.png'
        ),
        isCorrect: false
      }
    ]
  },
  {
    id: '2',
    title:
      'Олівер любить мистецтво, годинами гуляє по галереях, фотографує унікальні інсталяції.\n' +
      'Хто з цих хлопців Олівер?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/2.1.png'
        ),
        isCorrect: true
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/2.2.png'
        ),
        isCorrect: false
      }
    ]
  },
  {
    id: '3',
    title:
      'Джейкоб фанат нічного життя. Він обожнює клуби та часто отримує запрошення на найгучніші вечірки.\n' +
      'Як ти думаєш, де Джейк і яка його стихія?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/3.1.png'
        ),
        isCorrect: false
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/3.2.png'
        ),
        isCorrect: true
      }
    ]
  },
  {
    id: '4',
    title:
      'Елізабет прихильниця свідомого життя та шопінгу у вінтажних магазинах. Любить фешн та художні виставки.\n' +
      'Хто з них Елізабет?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/4.1.png'
        ),
        isCorrect: true
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/4.2.png'
        ),
        isCorrect: false
      }
    ]
  },
  {
    id: '5',
    title:
      'Герман досліджує драматургію та акторську майстерність. Усі вечори він проводить у храмі Мельпомени.\n' +
      'На якому фото Герман і до якої культури він належить?',
    imageList: [
      {
        id: '1',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/5.1.png'
        ),
        isCorrect: false
      },
      {
        id: '2',
        url: getImageFromS3(
          '/images/representative-of-subculture-challenge/5.2.png'
        ),
        isCorrect: true
      }
    ]
  }
];

export const representativeOfSubcultureResultList = [
  {
    resultId: '1',
    titleDescription: 'Більшості героїв Сохо вдалось залишитись нерозгаданими',
    textDescription:
      'В людях для тебе найбільш важлива особистість, а не соціальні атрибути.',
    image: getImageFromS3(
      'images/representative-of-subculture-challenge/result1.png'
    )
  },
  {
    resultId: '2',
    titleDescription: 'Тобі вдалось розгадати більшість наших героїв',
    textDescription: 'Вітаємо з хорошим результатом!',
    image: getImageFromS3(
      'images/representative-of-subculture-challenge/result2.png'
    )
  },
  {
    resultId: '3',
    titleDescription: 'Вау! Ти відгадав усіх персонажів',
    textDescription: 'Розбиратися в людях — це твоя стихія.',
    image: getImageFromS3(
      'images/representative-of-subculture-challenge/result3.png'
    )
  }
];

export const trueFalseSohoChallengeList = [
  {
    id: '1',
    title: 'Сохо — це житловий район, що розташований у Мангеттені в Нью-Йорку',
    image: getImageFromS3('/images/true-false-challenge/soho/1.png'),
    isCorrect: true
  },
  {
    id: '2',
    title:
      'У XIX столітті Сохо називали\n' +
      '"Червоним районом" через\n' +
      'популярність кабаре і нічних клубів',
    image: getImageFromS3('/images/true-false-challenge/soho/2.png'),
    isCorrect: false
  },
  {
    id: '3',
    title:
      'Сохо славиться чавунними будівлями, в яких розташовані художні галереї та бутики',
    image: getImageFromS3('/images/true-false-challenge/soho/3.png'),
    isCorrect: true
  },
  {
    id: '4',
    title:
      'На території Сохо раніше знаходилися сади, які належали заможним сім’ям',
    image: getImageFromS3('/images/true-false-challenge/soho/4.png'),
    isCorrect: false
  },
  {
    id: '5',
    title:
      'Сохо вважається одним\n' +
      'із найкращих районів для\n' +
      'гастрономічного туризму\n' +
      'в Нью-Йорку',
    image: getImageFromS3('/images/true-false-challenge/soho/5.png'),
    isCorrect: true
  },
  {
    id: '6',
    title:
      'Сохо був промисловим районом до 1970-х років, а потім його почали заселяти художники',
    image: getImageFromS3('/images/true-false-challenge/soho/6.png'),
    isCorrect: true
  }
];

export const trueFalseSohoResultList = [
  {
    resultId: '1',
    text:
      'Схоже, що ти місцевий у Сохо.\n' +
      'Ти знаєш багато фактів про цей район і можеш дивувати людей навколо своїми знаннями.',
    image: getImageFromS3('images/true-false-challenge/soho/result1.png')
  },
  {
    resultId: '2',
    text:
      'Тебе не цікавить історія, адже ти живеш лише сьогоденням.\n' +
      'Ти природжений новатор, що прагне створювати унікальні речі.',
    image: getImageFromS3('images/true-false-challenge/soho/result2.png')
  },
  {
    resultId: '3',
    text:
      '6 з 6. Складається враження,\n' +
      'що ти все життя прожив у Сохо\n' +
      'і щодня проводиш екскурсії\n' +
      'для туристів.\n' +
      'Твоїм знанням можна лише позаздрити!',
    image: getImageFromS3('images/true-false-challenge/soho/result3.png')
  }
];
