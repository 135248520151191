import React, { useEffect, useState, useContext } from 'react';
import MenuScreen from '../common/menu-screen';
import InfoScreen from '../components/lm-d-s/info-screen';
import CharacteristicScreen from '../components/lm-d-s/characteristic-screen';
import FinalScreen from '../components/lm-d-s/final-screen';
import ErrorScreen from '../components/lm-d-s/errorScreen';
import styles from '../components/chesterfield/index.module.scss';
import { ScreensContext } from '../contexts/screensContext';
import WelcomeChallengeScreen from '../common/challenge-component/welcome-challenge-screen';
import TwoImageSelectChallenge from '../common/challenge-component/two-image-select-challenge';
import ResultInfoScreen from '../common/challenge-component/result-info-screen';
import { DataLayerEventContext } from '../contexts/dataLayerEventContext';
import {
  decemberChallengeList,
  decemberResultList,
  februaryChallengeList,
  februaryResultList,
  januaryChallengeList,
  januaryResultList,
  novemberResultList,
  octoberChallengeList,
  octoberResultList,
  septemberChallengeList,
  septemberResultList
} from '../consts/chesterfield-challenge';
import OnlyImageChallenge from '../common/challenge-component/only-image-challenge';
import SwipeChallenge from '../common/challenge-component/swipe-challenge';
import AllBrandsScreen from '../common/all-brands-screen';
import BrandLineScreen from '../common/brand-line-screen';
import ForesightScreen from '../components/lm-d-s/foresight-screen';
import FactoryScreen from '../common/factory-screen';
import CommerceVideoScreen from '../components/commerce-video-screen/CommerceVideoScreen';
import { getImageFromS3 } from '../imageService';
import ReviewFlowScreen from '../common/review-flow-screen';
import ResidentsWhichCountryChallenge from '../common/challenge-component/residents-which-country-challenge';

const menuList = [
  {
    id: 'challenge',
    screenNumber: 15,
    name: 'Челендж квітня',
    icon: 'images/common/menu-icon/may-challenge-menu-icon.svg'
  },
  {
    id: 'foresight',
    screenNumber: 27,
    name: 'Передбачення на сьогодні',
    icon: 'images/lm-double-splash/foresight-menu-icon.svg'
  },
  {
    id: 'characteristic',
    screenNumber: 4,
    name: 'Характеристики сигарет',
    icon: 'images/common/menu-icon/characteristic-menu-icon.svg'
  },
  {
    id: 'info',
    screenNumber: 3,
    name: 'Все про Філіп Морріс Україна',
    icon: 'images/common/menu-icon/info-menu-icon.svg'
  },
  {
    id: 'factory',
    screenNumber: 2,
    name: 'Нова фабрика на Львівщині',
    icon: 'images/common/menu-icon/authority-menu-icon.svg'
  },
  {
    id: 'review',
    screenNumber: 8,
    name: 'Залишити відгук про продукт',
    icon: 'images/common/menu-icon/review-menu-icon.svg'
  }
];

export const swipeChallenge = [
  {
    title: 'Декорації з вінілових платівок',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/9.png')
  },
  {
    title: 'Одяг у стилі тай-дай',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/8.png')
  },
  {
    title: 'Віртуальна реальність',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/7.png')
  },
  {
    title: 'Елементи ретро в дизайні',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/6.png')
  },
  {
    title: 'Комбуча',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/5.png')
  },
  {
    title: 'Йога',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/4.png')
  },
  {
    title: 'Настільні ігри',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/3.png')
  },
  {
    title: 'Елементи 80-х у одязі',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/2.png')
  },
  {
    title: 'Фольклорні мотиви в сучасній музиці',
    image: getImageFromS3('/images/chesterfield-6/november-challenge/1.png')
  }
];

const selectedPack = {
  title: 'Chesterfield Original',
  pack: getImageFromS3('/images/packs/chesterfield/original-updated.png'),
  tobaccoFirst: 'Вміст смол, мг - 9',
  tobaccoSecond: 'Вміст нікотину, мг - 0.7',
  filterIcon: getImageFromS3('images/bond/filter.png'),
  background: `url("${getImageFromS3(
    'images/chesterfield-4/background.png'
  )}")`,
  characteristicInfoList: [
    {
      title: 'Формат ',
      description: '- King Size ("Кінг сайз")'
    },
    {
      title: 'Кількість сигарет у пачці ',
      description: '– 20'
    },
    {
      title: 'Кількість пачок у блоці ',
      description: '– 10'
    }
  ],
  filterCharacteristicList: [
    {
      description: (
        <span>
          Класичний ацетатний фільтр з високоякісної целюлози, який затримує
          тверді частинки сигаретного диму.
        </span>
      ),
      bordered: false
    }
  ]
};

const ChesO = () => {
  const [screenNumber, setScreenNumber] = useState(-2);
  const [endSessionType, setEndSessionType] = useState('SESSION_END');
  const [isDisplayedAll, setIsDisplayedAll] = useState(false);
  const [brandLine, setBrandLine] = useState('');

  const { init } = useContext(DataLayerEventContext);

  useEffect(() => window.scrollTo(0, 0), [screenNumber]);

  useEffect(() => {
    init('Ches-O');
  }, [init]);

  const getScreen = (number, cb) => {
    switch (number) {
      case -2: {
        return (
          <CommerceVideoScreen
            videoSrc={getImageFromS3('videos/factory-video.mp4')}
            setScreenNumber={cb}
            nextScreenNumber={1}
            changeScreenImmediately
            skipScreenImmediately
            controls
            buttonStyle="skip-video"
            hasButtonArrow
          />
        );
      }
      case -1: {
        return (
          <AllBrandsScreen
            setScreenNumber={cb}
            setBrandLine={(brand) => {
              setBrandLine(brand);
              setScreenNumber(26);
            }}
            handleBackButton={() => setScreenNumber(4)}
          />
        );
      }
      case 1: {
        return (
          <MenuScreen
            setScreenNumber={cb}
            menuList={menuList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 2: {
        return (
          <FactoryScreen
            setScreenNumber={cb}
            backButtonClick={() => setScreenNumber(1)}
            nextButtonClick={() => setScreenNumber(7)}
          />
        );
      }
      case 3: {
        return (
          <InfoScreen
            setScreenNumber={cb}
            setReviewScreenNumber={() => setScreenNumber(7)}
          />
        );
      }
      case 4: {
        return (
          <CharacteristicScreen
            brandId="chesterfield"
            isNewMixture={selectedPack?.isNewMixture}
            title={selectedPack?.title}
            setScreenNumber={setScreenNumber}
            filterCharacteristicList={selectedPack?.filterCharacteristicList}
            infoList={selectedPack?.characteristicInfoList}
            tobaccoFirst={selectedPack?.tobaccoFirst}
            tobaccoSecond={selectedPack?.tobaccoSecond}
            packImage={selectedPack?.pack}
            handleBackButton={() => setScreenNumber(1)}
            handleNextButton={() => setScreenNumber(7)}
            fullRangeButtonClick={() => setScreenNumber(-1)}
            backgroundImage={selectedPack?.background}
            filterIcon={selectedPack?.filterIcon}
            nextButtonTitle="Далі"
          />
        );
      }
      case 5: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title="Обери з 2-х речей"
            description={`Тренди невпинно народжуються та змінюються.\n А ти намагаєшся встигнути за сучасними тенденціями чи обираєш вічну класику?`}
            buttonTitle="Пройти тест"
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(6)}
          />
        );
      }
      case 6: {
        return (
          <TwoImageSelectChallenge
            setScreenNumber={cb}
            challengeList={septemberChallengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            resultList={septemberResultList}
            setAfterResultScreen={() => setScreenNumber(25)}
          />
        );
      }
      case 25: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            setViewScreenNumber={() => setScreenNumber(4)}
            title="Згоден з результатами?"
            description={`Найкращий тренд сьогодні — це розвиток і самовдосконалення. А бути в тренді — значить ставати кращим щодня.\n Нижче можна зацінити, як покращився один відомий бренд.`}
            image={getImageFromS3(
              'images/chesterfield-6/september-challenge/result-info.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 7: {
        return (
          <FinalScreen
            setScreenNumber={cb}
            setScreenNumberToReview={() => setScreenNumber(8)}
            withReviewButton
            isUpdatedApp
          />
        );
      }
      case 8: {
        return (
          <ReviewFlowScreen
            setScreenNumber={cb}
            setSubmitReviewScreenNumber={() => setScreenNumber(24)}
          />
        );
      }
      case 9: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title="Модні тренди"
            description={`Усі знають, що мода циклічна.\n Ми підготували добірку модних трендів, які повернулися, щоб вразити всіх по-новому.`}
            buttonTitle="Дивитися"
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(10)}
          />
        );
      }
      case 10: {
        return (
          <OnlyImageChallenge
            challengeList={octoberChallengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setScreenNumber={setScreenNumber}
            setScreenNumberAfterResult={() => setScreenNumber(11)}
          />
        );
      }
      case 11: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            title={octoberResultList.title}
            description={octoberResultList.description}
            image={octoberResultList.image}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 12: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title="Які тренди тобі подобаються?"
            description={`Чи підвладний ти трендам? Пройди невеличкий тест, щоб перевірити.\n Правила: \nЯкщо тобі подобається тренд — свайпай вправо, якщо ні — вліво.`}
            buttonTitle="Почати"
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(13)}
          />
        );
      }
      case 13: {
        return (
          <SwipeChallenge
            setScreenNumber={setScreenNumber}
            setScreenNumberAfterResult={() => setScreenNumber(14)}
            challengeList={swipeChallenge}
            challengeResultList={novemberResultList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 14: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            setViewScreenNumber={() => setScreenNumber(4)}
            title="Як тобі результат?"
            description={`Найкращий тренд сьогодні — це розвиток і самовдосконалення. А бути в тренді — значить ставати кращим щодня.\n\n Нижче можна зацінити, як покращився один відомий бренд.`}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 15: {
        return (
          <ResidentsWhichCountryChallenge
            setScreenNumber={setScreenNumber}
            challengeId="youth-slang"
            afterResultButtonScreen={() => setScreenNumber(7)}
          />
          // <WelcomeChallengeScreen
          //   setScreenNumber={cb}
          //   title="Ідеї для персоналізації подарунків"
          //   description={`Усі люблять отримувати подарунки! Але важливо ще й уміти їх дарувати.\n\n Ми підготували кілька ідей, як зробити свій подарунок особливим і незабутнім.`}
          //   buttonTitle="Дивитися"
          //   backgroundImage={`url("${getImageFromS3(
          //     'images/chesterfield-4/background.png'
          //   )}")`}
          //   setNextScreenNumber={() => setScreenNumber(16)}
          // />
        );
      }
      case 16: {
        return (
          <OnlyImageChallenge
            onlyChallenge
            setScreenNumber={cb}
            challengeList={decemberChallengeList}
            setScreenNumberAfterResult={() => setScreenNumber(17)}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 17: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            title={decemberResultList.title}
            subtitle={decemberResultList.subtitle}
            description={decemberResultList.description}
            image={decemberResultList.image}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 18: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title="Що більше до вподоби"
            description={`Що тобі ближче: стримана вишуканість чи крейзі експерименти?\n Пройди тест і дізнайся!`}
            buttonTitle="Почати тест"
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(19)}
          />
        );
      }
      case 19: {
        return (
          <TwoImageSelectChallenge
            setScreenNumber={cb}
            challengeList={januaryChallengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            resultList={januaryResultList}
            setAfterResultScreen={() => setScreenNumber(20)}
          />
        );
      }
      case 20: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            title="Як тобі результат?"
            description="Яким би не був твій стиль, ми впевнені, що ти в ньому неперевершений."
            image={getImageFromS3(
              'images/chesterfield-6/january-challenge/result-info.png'
            )}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 21: {
        return (
          <WelcomeChallengeScreen
            setScreenNumber={cb}
            title="Оновлені тренди з минулого"
            description={`Як же круто, коли тренди повертаються!\n\n Ми підготували добірку колись підзабутих трендів для декору кімнати, що оновилися та зробили comeback.`}
            buttonTitle="Дивитися"
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setNextScreenNumber={() => setScreenNumber(22)}
          />
        );
      }
      case 22: {
        return (
          <OnlyImageChallenge
            challengeList={februaryChallengeList}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
            setScreenNumber={setScreenNumber}
            setScreenNumberAfterResult={() => setScreenNumber(23)}
          />
        );
      }
      case 23: {
        return (
          <ResultInfoScreen
            setScreenNumber={setScreenNumber}
            setNextScreenNumber={() => setScreenNumber(7)}
            title={februaryResultList.title}
            subtitle={februaryResultList.subtitle}
            description={februaryResultList.description}
            image={februaryResultList.image}
            backgroundImage={`url("${getImageFromS3(
              'images/chesterfield-4/background.png'
            )}")`}
          />
        );
      }
      case 24: {
        return <FinalScreen setScreenNumber={cb} />;
      }
      case 26: {
        return (
          <BrandLineScreen
            brandId={brandLine}
            setScreenNumber={setScreenNumber}
            backButtonClick={() => setScreenNumber(-1)}
            nextButtonClick={() => setScreenNumber(7)}
          />
        );
      }
      case 27: {
        return (
          <ForesightScreen
            setScreenNumber={setScreenNumber}
            handleBackButton={() => setScreenNumber(1)}
          />
        );
      }
      default: {
        return <ErrorScreen setScreenNumber={cb} />;
      }
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <ScreensContext.Provider
          value={{
            endSessionType,
            setEndSessionType,
            isDisplayedAll,
            setIsDisplayedAll
          }}
        >
          {getScreen(screenNumber, setScreenNumber)}
        </ScreensContext.Provider>
      </div>
    </div>
  );
};

export default ChesO;
