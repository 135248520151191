import React, { useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import global from '../../index.module.scss';
import HeadContainer from '../../head-container';
import { getImageFromS3 } from '../../../imageService';

const PercentageOfYourBohemianChallenge = ({
  setScreenNumber,
  setAfterResultScreen,
  challengeList,
  resultList,
  backgroundImage
}) => {
  const [challengeStep, setChallengeStep] = useState(0);
  const [selectQuestion, setSelectQuestion] = useState(challengeList[0]);
  const [challengeResult, setChallengeResult] = useState([]);
  const [isResultView, setIsResultView] = useState(false);
  const [isAfterResult, setIsAfterResult] = useState(false);
  const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
  const [result, setResult] = useState(null);

  const handleSelect = (answer) => {
    const isExistAnswer = challengeResult.find(
      (item) => item.questionId === selectQuestion.id
    );
    if (isExistAnswer) {
      const updatedResultList = challengeResult.filter(
        (item) => item.questionId !== selectQuestion.id
      );
      setChallengeResult([
        ...updatedResultList,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    } else {
      setChallengeResult([
        ...challengeResult,
        { questionId: selectQuestion.id, answerId: answer.id }
      ]);
    }
  };

  const handleViewResult = () => {
    let firstCount = 0;
    let secondCount = 0;

    for (const item of challengeResult) {
      if (item.answerId === '1') firstCount++;
      else if (item.answerId === '2') secondCount++;
    }

    const answerIndex =
      firstCount > secondCount ? 0 : firstCount < secondCount ? 1 : 2;

    setResult(resultList[answerIndex]);
    setIsResultView(true);
  };

  return (
    <div
      className={styles.imageSelectContainer}
      style={{ backgroundImage: backgroundImage }}
    >
      <HeadContainer setScreenNumber={setScreenNumber} />
      {isWelcomeScreen && !isResultView && (
        <div className={styles.welcomeContainer}>
          <div className={styles.headContainer}>
            <img
              src={getImageFromS3(
                'images/percentage-of-your-bohemian-challenge/circle.png'
              )}
              alt="welcome-icon"
            />
            <p className={styles.title}>Відсоток твоєї богемності</p>
          </div>
          <p className={styles.description}>
            Не сумніваємось у твоїй креативності та екстраординарності.<br></br>
            Але як щодо перевірки того, яке у тобі співвідношення стабільності
            та новаторства?
          </p>
          <button onClick={() => setIsWelcomeScreen(false)}>
            Пройти тест!
          </button>
        </div>
      )}
      {!isResultView && !result && !isWelcomeScreen && !isAfterResult && (
        <>
          <div className={styles.stepToolbar}>
            {challengeList.map((item, index) => (
              <div
                className={classNames(
                  styles.stepItem,
                  index === challengeStep && styles.activeStepItem
                )}
              />
            ))}
          </div>
          {selectQuestion && (
            <div className={styles.questionItem}>
              <span className={styles.titleQuestion}>
                {selectQuestion.title}
              </span>
              <div className={styles.imageList}>
                {selectQuestion.imageList.map((item) => {
                  const answer = challengeResult?.find(
                    (item) => item.questionId === selectQuestion.id
                  );

                  return (
                    <div
                      id={item.id}
                      onClick={() => handleSelect(item)}
                      className={classNames(
                        styles.imageContainer,
                        answer?.answerId === item.id &&
                          styles.selectedImageContainer
                      )}
                    >
                      <img
                        src={item.url}
                        alt={item.id}
                        className={styles.imageItem}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className={styles.buttonContainer}>
            {challengeStep !== 0 && (
              <button
                className={global.reviewButton}
                onClick={() => {
                  setSelectQuestion(challengeList[challengeStep - 1]);
                  setChallengeStep(challengeStep - 1);
                }}
              >
                Назад
              </button>
            )}
            <button
              className={global.menuButton}
              onClick={() => {
                if (challengeList.length > challengeStep + 1) {
                  setSelectQuestion(challengeList[challengeStep + 1]);
                  setChallengeStep(challengeStep + 1);
                } else {
                  handleViewResult();
                }
              }}
              disabled={
                !challengeResult.find(
                  (item) => item.questionId === selectQuestion.id
                )
              }
            >
              Далі
            </button>
          </div>
        </>
      )}
      {isResultView && result && (
        <div className={styles.resultContainer}>
          <div>
            <h3 className={styles.resultHeading}>Результат</h3>
          </div>
          <img src={result?.image} alt="result" />
          <div className={styles.description}>
            <p className={styles.resultTitle}>{result.titleDescription}</p>
            <p className={styles.resultDescription}>{result.textDescription}</p>
          </div>
          <button
            className={global.reviewButton}
            onClick={() => {
              setIsAfterResult(true);
              setIsResultView(false);
              setIsWelcomeScreen(false);
            }}
          >
            Далі
          </button>
        </div>
      )}
      {isAfterResult && !isResultView && !isWelcomeScreen && (
        <div className={styles.afterResultContainer}>
          <img
            src={getImageFromS3(
              'images/percentage-of-your-bohemian-challenge/after-result.png'
            )}
            alt="after-result"
          />
          <p className={styles.title}>Було цікаво розгадувати?</p>
          <div className={styles.description}>
            Неважливо, чи любиш ти зміни чи стабільність, адже обидва варіанти
            мають свої переваги!
          </div>
          <button className={global.menuButton} onClick={setAfterResultScreen}>
            Далі
          </button>
        </div>
      )}
    </div>
  );
};

export default PercentageOfYourBohemianChallenge;
