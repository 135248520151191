import React, { memo } from 'react';
import styles from './index.module.scss';
import global from '../../index.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default memo(function ProgressQuestionStep({ answer, data }) {
  return (
    <div>
      <div className={styles.bodyContainer}>
        <div className={styles.title}>
          <p>{data.title}</p>
        </div>
        <div className={styles.image}>
          <LazyLoadImage
            src={data.image}
            width={'100%'}
            height={279}
            alt={data.title}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={global.menuButton} onClick={answer}>
          Правда
        </button>
        <button className={global.errorButton} onClick={answer}>
          Брехня
        </button>
      </div>
    </div>
  );
});
